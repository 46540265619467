/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, TitleMain, Subtitle, Button, Title, Text, Image, YouTube, PriceWrap, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-ehfeyb css-42e4bw --style3 js-anim  --anim7 --anim-s5 --full pb--10" anim={"7"} name={"uvod"} animS={"5"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/ebaaff47b4124701bf556a3f22b49913_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/ebaaff47b4124701bf556a3f22b49913_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/ebaaff47b4124701bf556a3f22b49913_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/ebaaff47b4124701bf556a3f22b49913_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/ebaaff47b4124701bf556a3f22b49913_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/ebaaff47b4124701bf556a3f22b49913_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/ebaaff47b4124701bf556a3f22b49913_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/ebaaff47b4124701bf556a3f22b49913_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" style={{"maxWidth":737,"paddingTop":18,"paddingBottom":51}} anim={"6"} animS={"5"}>
              
              <TitleMain className="title-box title-box--style3 fs--24 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: var(--color-blend--95);\">PŮJČOVNA MINIBAGRŮ OSTRAVA&nbsp;</span><br>"}>
              </TitleMain>

              <Subtitle className="subtitle-box fs--36 lh--14" style={{"maxWidth":459,"marginTop":31}} content={"<span style=\"color: var(--white);\">Potřebujete vykopat\nzáklady na dům či jímku\na nechcete si na to najímat drahé firmy?</span><br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--style6 fs--48" style={{"marginTop":54,"maxWidth":647}} content={"<span style=\"color: var(--color-blend--95);\">Půjčte si jednoduše bagr&nbsp;</span><br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape3 btn-box--design3 fs--20" style={{"marginTop":48}} content={"Ceník&nbsp;"} use={"page"} href={"/#cenik"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" style={{"backgroundColor":"var(--color-custom-2)"}} name={"0akqw4tnphsh"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"marginTop":14}} content={"<span style=\"font-weight: normal;\">nyní za </span>4700 Kč&nbsp;<br>"}>
              </Subtitle>

              <Title className="title-box w--900 ls--08" style={{"marginTop":11}} content={"<span style=\"color: var(--color-blend--95);\">VÍKENDOVÁ AKCE\n</span>"}>
              </Title>

              <Text className="text-box w--400 ls--02 lh--14" style={{"maxWidth":650,"marginTop":17}} content={"<span style=\"color: var(--color-supplementary);\">PŮJČENÍ MINIBAGRU<br>OD PÁTKU <span style=\"font-weight: bold;\">17:00</span> DO PONDĚLÍ <span style=\"font-weight: bold;\">10:00</span></span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape3 fs--20" style={{"marginTop":21}} content={"POPTAT MINIBAGR"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --center" anim={"2"} animS={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Ušetřete čas i peníze!\n</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":800}} content={"Provádění prací svépomocí je sice finančně úsporné, ale neobejde se bez pořádných svalů nebo kvalitní techniky. Pokud tedy plánujete ušetřit peníze a nechcete se zbytečně dřít ani vyhazovat peníze za profesionální výkopové firmy, můžete si bagr jednoduše pronajmout.\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"var(--color-blend--95)"}} name={"md20278eygo"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 pb--16 pl--08 pr--08 pt--12" style={{"backgroundColor":"var(--white)"}} anim={"2"}>
              
              <Image style={{"maxWidth":100}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""} alt={"Ušetříte za práci"} src={"https://cdn.swbpg.com/o/2124/952aab47d79149a1a32ce593ec58b683.svg"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":23}} content={"<span style=\"color: var(--color-custom-2);\">Ušetříte za práci</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":18}} content={"Práci, kterou byste jindy dělali svépomocí i několik dní, zvládnou naše bagry za jedno odpoledne. Nedřete se zbytečně.\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pb--16 pl--08 pr--08 pt--12" style={{"backgroundColor":"var(--white)"}} anim={"2"}>
              
              <Image style={{"maxWidth":100}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""} alt={"Bagr k Vám dovezeme"} src={"https://cdn.swbpg.com/o/2124/5a590166681543db881046a30f0dcf6c.svg"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":23}} content={"<span style=\"color: var(--color-custom-2);\">Bagr k Vám dovezeme</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":18}} content={"Bagr přivezeme až k Vám v předem domluvený čas. Po dokončení Vašich prací, si pro něj zase přijedeme. Rozhodli jste se, že chcete kopat dnes? Kontaktujte nás a svůj bagr můžete mít u sebe už za pár hodin.\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pb--16 pl--08 pr--08 pt--12" style={{"backgroundColor":"var(--white)"}} anim={"2"}>
              
              <Image style={{"maxWidth":100}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""} alt={"Zaškolíme"} src={"https://cdn.swbpg.com/o/2124/9b822bdea044496588ab60f04b6b076c.svg"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":23}} content={"<span style=\"color: var(--color-custom-2);\">Zaškolíme&nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":18}} content={"Obsluha bagru je relativně jednoduchá. Stroje jsou koncipovány tak, aby je zvládl obsluhovat i amatér. Před zapůjčením Vám však samozřejmě poskytneme krátké školení k obsluze stroje.\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 pb--16 pl--08 pr--08 pt--12" style={{"backgroundColor":"var(--white)"}} anim={"2"}>
              
              <Image style={{"maxWidth":100}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={""} alt={"Cenově výhodně"} src={"https://cdn.swbpg.com/o/2124/4e560400b261451996708d519a5580ab.svg"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"marginTop":23}} content={"<span style=\"color: var(--color-custom-2);\">Cenově výhodně&nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"marginTop":18}} content={"Půjčení bagru se Vám vyplatí. Platíte pouze za pronájem stroje, nikoliv za práci bagristy. Při stavbě domu tím můžete ušetřit až několik tisíc.\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"vo007v3rzzq"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--left" style={{"marginTop":23}} content={"Minibagr – Takeuchi TB216<br>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"color: var(--color-custom-2);\">(japonský minibagr), hmotnost 1,8 tuny = skutečné kopání</span><br>"}>
              </Text>

              <Text className="text-box text-box--left" content={"<span style=\"background-color: rgb(74, 144, 226);\"><span style=\"color: var(--white);\">&nbsp;✔</span>&nbsp;</span>&nbsp;Roztahovací podvozek (pro práci v obtížném terénu si zvýšíte stabilitu a efektivitu práce)"}>
              </Text>

              <Text className="text-box text-box--left" content={"<span style=\"background-color: rgb(74, 144, 226);\"><span style=\"color: var(--white);\">&nbsp;✔</span>&nbsp;</span>&nbsp;3ks lžící: 30cm, 40cm a 90cm <span style=\"font-weight: bold; color: var(--color-supplementary);\">V CENĚ</span>"}>
              </Text>

              <Text className="text-box text-box--left" content={"Japonský motor a zpracování = s bagrem se dá skutečně kopat, třiválcový motor"}>
              </Text>

              <Text className="text-box text-box--left" content={"Mimoosé kopání = k čemu? díky němu perfektně okopete například základy domu nebo bezpečně ukončíte přípojky u domu"}>
              </Text>

              <Text className="text-box text-box--left" content={"Skutečně široká přední radlice = perfektní možnost rovnání terénu a hrnutí před sebo"}>
              </Text>

              <Text className="text-box text-box--left" content={"Stříška = děláte i v dešti, zvýšené bezpečí při převrácení"}>
              </Text>

              <Text className="text-box text-box--left" content={"Silný led reflektor pro práci v šeru/noci"}>
              </Text>

              <Text className="text-box text-box--left" content={"Přídavná hydraulika (nasadíte kladivo, vrták apod.)"}>
              </Text>

              <Text className="text-box text-box--left" content={"<a href=\"https://www.takeuchiglobal.com/wp-content/uploads/2019/03/A4-TB216-Brochure-HO-Feb-2019.pdf\">Zobrazit technické parametry stroje</a>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape3 btn-box--left fs--18" style={{"marginTop":47}} content={"POPTAT MINIBAGR"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <YouTube embedId={"cIyZV5gcekQ"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" style={{"paddingBottom":44}} layout={"l12"} name={"efe8wzz7toc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2124/59ef96a36e5045038c645965a5fd3086_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2124/59ef96a36e5045038c645965a5fd3086_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/59ef96a36e5045038c645965a5fd3086_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2124/dbf17893f5a948cdb116e5dbe57b48c4_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2124/dbf17893f5a948cdb116e5dbe57b48c4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/dbf17893f5a948cdb116e5dbe57b48c4_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2124/d3d5e1084ba54941a2cc585e098ae1e8_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2124/d3d5e1084ba54941a2cc585e098ae1e8_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2124/d3d5e1084ba54941a2cc585e098ae1e8_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2124/90e60d8aceb94943a15e3e89c98347e0_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2124/90e60d8aceb94943a15e3e89c98347e0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/90e60d8aceb94943a15e3e89c98347e0_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2124/9711d8677c304309968dfc8ee7e0143e_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2124/9711d8677c304309968dfc8ee7e0143e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/9711d8677c304309968dfc8ee7e0143e_s=660x_.jpg 660w"}>
              </Image>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape3 fs--18" style={{"marginTop":21}} content={"POPTAT MINIBAGR"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/2124/b2ca3bb55aaa41e2a09d22cbb00fcb57_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2124/b2ca3bb55aaa41e2a09d22cbb00fcb57_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/b2ca3bb55aaa41e2a09d22cbb00fcb57_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cenik"}>
          
          <ColumnWrap className="column__flex el--1" style={{"maxWidth":1050}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"marginTop":0,"marginBottom":47}} content={"<span style=\"color: var(--color-custom-2);\">Ceník půjčovného</span>"}>
              </Subtitle>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"2 dny pronájmu (16 mth):"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"<span style=\"color: var(--color-blend--05);\">4 500 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"3 dny pronájmu (24 mth):"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"<span style=\"color: var(--color-blend--05);\">10 000 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"4 dny a více:"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"<span style=\"color: var(--color-blend--05);\">INDIVIDUÁLNĚ</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"víkend (pátek 17:00 – pondělí 10:00), v ceně 16 mth:"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"<span style=\"color: var(--color-blend--05);\">4 700 Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4","marginTop":31}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"kauce"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"<span style=\"color: var(--color-blend--05);\">12 000 Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <Text className="text-box w--500" style={{"maxWidth":900,"marginTop":16}} content={"<span style=\"color: var(--color-blend--05);\">MOŽNOST DOPRAVY STROJE V CENĚ 24Kč/km (počítá se tam i zpět) z Frýdlantu n.O. + manipulace 300Kč\n  \n</span>"}>
              </Text>

              <Text className="text-box w--700" style={{"maxWidth":900,"marginTop":16}} content={"<span style=\"color: var(--black);\">Doplňující informace:  \n</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":900,"marginTop":9}} content={"mth - motohodiny provozu minibagru, bagrování\n  \n"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":900,"marginTop":0}} content={"Půjčení bagru 1 den = max. 8 mth (Víkendová cena SO + NE = max. 16 mth)\n\n  \n"}>
              </Text>

              <Text className="text-box w--500" style={{"maxWidth":900,"marginTop":9}} content={"Do ceny dopravy se započítává:"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":900,"marginTop":0}} content={"– cesta tam a zpět při dopravě minibagru k Vám"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":900,"marginTop":0}} content={"– cesta tam a zpět při dopravě minibagru od Vás"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape3 fs--18" style={{"marginTop":21}} content={"POPTAT MINIBAGR"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"smzb3q3fe69"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":1050}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Bagr půjčujeme i pro drobné práce\n</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":900,"marginTop":16}} content={"Velké výkopové firmy dávají přednost dlouhodobějším zakázkám, které se jim více vyplatí. Pokud na svém pozemku zvažujete vykopat například pouze jámu na odpadní jímku, může se Vám stát, že budete výkopovou firmou odmítnutí. To se u nás nestane. Bagry půjčujeme bez ohledu na to, jak dlouho jej chcete mít půjčený.  \n"}>
              </Text>

              <Divider style={{"backgroundColor":"var(--color-blend--50)","maxWidth":""}}>
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Kopejte bez ohledu na sezónu\n\n\n</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":900,"marginTop":16}} content={"Výkopové firmy jsou limitovány nejen počtem strojů, ale také zaměstnanců. Proto se, zejména na jaře, kdy je největší poptávka po výkopových pracích, může stát, že nenaleznete firmu s volnou kapacitou. Díky tomu, že naše stroje obsluhujete vy sami, nemusíte se starat o to, zda má bagrista čas či nikoliv.  \n"}>
              </Text>

              <Divider style={{"backgroundColor":"var(--color-blend--50)","maxWidth":""}}>
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Výkopové práce ve vlastní režii\n\n</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":900,"marginTop":16}} content={"Díky tomu, že si od nás stroj půjčujete do vlastních rukou, můžete si naplánovat práci dle Vašich představ a časových možností. Nezaskočí Vás tedy ani vzniklé časové prodlevy. Nikdo na Vás nemusí čekat a vy nebudete pod zbytečným tlakem. Výhodou také je, že nemusíte nikomu nic složitě vysvětlovat, sednete si do stroje a uděláte vše dle vlastních představ.\n  \n"}>
              </Text>

              <Divider style={{"backgroundColor":"var(--color-blend--50)","maxWidth":""}}>
              </Divider>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape3 fs--18" style={{"marginTop":41}} content={"POPTAT MINIBAGR"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--25" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--2" style={{"maxWidth":1420}} anim={"2"} animS={"2"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20" content={"<span style=\"color: var(--white);\"><a href=\"https://minibagry-ostrava.cz/\">MINIBAGRY-OSTRAVA.CZ\n</a></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"petr541@gmail.com"}>
              </Text>

              <Text className="text-box" content={"Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}